import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';
import BlogListing from '../components/BlogListing';

const BlogSingleTemplate = ({
  data: {
    datoCmsBlog: {
      seoMetaTags,
      title,
      bannerImage,
      bannerText,
      bannerDarkOverlayOpacity,
      modularBlocks,
    },
    allDatoCmsBlog,
  },
}) => (
  <Layout seo={seoMetaTags}>
    <main>
      <Banner
        heading={title}
        image={bannerImage}
        text={bannerText}
        breadcrumbs={[
          {
            text: 'Blog',
            urlPath: 'blog',
          },
        ]}
        darkOverlayOpacity={bannerDarkOverlayOpacity}
      />
      <ModularBlocks items={modularBlocks} isBlogSingle={true} />
    </main>
    <BlogListing items={allDatoCmsBlog.nodes} />
  </Layout>
);

export const BlogSingleTemplateQuery = graphql`
  query BlogSingleTemplateQuery($id: String!) {
    datoCmsBlog(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage: featuredImage {
        ...SubPageBannerImageFragment
      }
      bannerText
      bannerDarkOverlayOpacity
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FullWidthImageModularBlockFragment
      }
    }
    allDatoCmsBlog(
      filter: { id: { ne: $id } }
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 2
    ) {
      nodes {
        ...BlogCardFragment
      }
    }
  }
`;

export default BlogSingleTemplate;
